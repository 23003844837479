import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {
    private helper = new JwtHelperService();

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      if (this.isLoggedIn()) {
            return true;
        } else {
            // not logged in so redirect to login page
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
            return false;
        }
    }

    isLoggedIn() {
        const jwtToken = localStorage.getItem('currentUser');

        if (jwtToken == null) {
            return false;
        }

        const decodedToken = this.helper.decodeToken(jwtToken);
        const expirationDate = this.helper.getTokenExpirationDate(jwtToken);
        const isExpired = this.helper.isTokenExpired(jwtToken);

        if (localStorage.getItem('currentUser') && !isExpired) {
            // logged in so return true
            return true;
        }

        return false;
    }

    currentUser(): String {
        const userObj = JSON.parse(localStorage.getItem('currentUser'));
        return this.capitalizeFirstLetter(userObj.username);
    }

    capitalizeFirstLetter(string) {
        return string[0].toUpperCase() + string.slice(1);
    }
}
