import { Component, OnInit, OnDestroy } from '@angular/core';
import { TemperatureService } from '../services/temperature.service';
import { ChartsModule } from 'ng2-charts';
import { Observable, Subscription } from 'rxjs/Rx';
import 'rxjs/add/operator/switchMap';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';
import { TemperatureReadingModelHistory } from '../models/tempread-history-model';
import { ChartType } from 'chart.js';


@Component({
  selector: 'app-temperaturehistory',
  templateUrl: './temperaturehistory.component.html',
  styleUrls: ['./temperaturehistory.component.css'],
  providers: [TemperatureService]
})
export class TemperaturehistoryComponent implements OnInit, OnDestroy {
  sensorId: String;
  sensorIdSub: Subscription;

  historyHead: TemperatureReadingModelHistory;
  historyArr: Array<TemperatureReadingModelHistory>;

  public lineChartLabels: any = new Object();
  lineChartData: Array<any> = new Array<any>();

  constructor(
    private temperatureService: TemperatureService,
    private route: ActivatedRoute
  ) { }

  public lineChartType : ChartType = 'line';
  public lineChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
            maxTicksLimit: 12
          }
        }],
      yAxes: [
        {
          name: 'Tempeature',
          id: 'TEMP',
          type: 'linear',
          position: 'right',
          display: true,
        }
      ]
    },
    elements: {
      point: {
        radius: 0.2
      }
    },
    legend: {
      display: false
    }
  };

  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // Blue
      backgroundColor: 'rgba(50,162,236,0.2)',
      borderColor: 'rgba(50,162,236,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // Red
      backgroundColor: 'rgba(220,20,60,0.2)',
      borderColor: 'rgba(220,20,60,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  ngOnInit() {
    // Create subscription to SensorID updates
    this.sensorIdSub = this.route.paramMap.switchMap((params: ParamMap) => this.sensorId = String(params.get('id')))
      .subscribe(t => this.updateData());
  }

  ngOnDestroy() {
    this.sensorIdSub.unsubscribe();
  }

  private updateData() {
    const dataArrTempAvg = new Array<number>();
    const dataArrTempMin = new Array<number>();
    const dataArrTempMax = new Array<number>();
    const labelArr = new Array<String>();

    // Delete old data, we get all the data at every poll
    this.lineChartData.splice(0, this.lineChartData.length);

    // Do call to WIUP REST Service
    this.temperatureService.getHistoryForSensor(this.sensorId).subscribe(
      (data: Array<TemperatureReadingModelHistory>) => {
        this.historyArr = data;

        // Take first element of array and set to variable, used for getting Sensor name / description
        if (this.historyArr.length > 0) {
          this.historyHead = this.historyArr[0];
        }

        for (const element of this.historyArr.reverse()) {
          dataArrTempAvg.push(element.avgTemp);
          dataArrTempMin.push(element.minTemp);
          dataArrTempMax.push(element.maxTemp);
          labelArr.push(moment(element.sensorReadingDate).format('DD/MM-YY'));
        }

        const returnObjectTempAvg = new Object();
        returnObjectTempAvg['data'] = dataArrTempAvg;
        returnObjectTempAvg['label'] = 'Avg temp';
        returnObjectTempAvg['fill'] = 'false';
        returnObjectTempAvg['yAxisID'] = 'TEMP';
        this.lineChartData.push(returnObjectTempAvg);

        const returnObjectTempMin = new Object();
        returnObjectTempMin['data'] = dataArrTempMin;
        returnObjectTempMin['label'] = 'Min temp';
        returnObjectTempMin['fill'] = 'false';
        returnObjectTempMin['yAxisID'] = 'TEMP';
        this.lineChartData.push(returnObjectTempMin);

        const returnObjectTempMax = new Object();
        returnObjectTempMax['data'] = dataArrTempMax;
        returnObjectTempMax['label'] = 'Max temp';
        returnObjectTempMax['fill'] = 'false';
        returnObjectTempMax['yAxisID'] = 'TEMP';
        this.lineChartData.push(returnObjectTempMax);

        this.lineChartLabels = labelArr;
      },
      error => console.log(error)
    );
  }
}
