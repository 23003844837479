import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { SchedulesComponent } from './schedules/schedules.component';
import { TemperaturesComponent } from './temperatures/temperatures.component';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './services/index';
import { ChartsModule } from 'ng2-charts';
import { AuthGuard } from './auth/auth-guard.service';
import { AddscheduleComponent } from './addschedule/addschedule.component';
import { EnergyComponent } from './energy/energy.component';
import { EnergyListComponent } from './energy-list/energy-list.component';
import { ErrorInterceptor } from './auth/errorinterceptor';
import { AlertComponent } from './directives/alert.component';
import { SocketService } from './services/socket-service';
import { PressureComponent } from './pressure/pressure.component';
import { TemperaturehistoryComponent } from './temperaturehistory/temperaturehistory.component';
import { StatuspageComponent } from './statuspage/statuspage.component';

const appRoutes: Routes = [
  { path: 'temperature', component: TemperaturesComponent },
  { path: 'temperature/:id', component: TemperaturesComponent },
  { path: 'temperature/history/', component: TemperaturehistoryComponent },
  { path: 'temperature/history/:id', component: TemperaturehistoryComponent },
  { path: 'energy', component:  EnergyComponent},
  { path: 'pressure', component:  PressureComponent},
  { path: 'energy/list', component:  EnergyListComponent},
  { path: 'schedule', component:  SchedulesComponent, canActivate: [AuthGuard]},
  { path: 'schedule/:id/edit', component:  SchedulesComponent, canActivate: [AuthGuard]},
  { path: 'schedule/add', component:  AddscheduleComponent, canActivate: [AuthGuard]},
  { path: 'status', component: StatuspageComponent },
  { path: 'login', component:  LoginComponent},
  { path: 'logout', component:  LoginComponent},
  { path: '',
    redirectTo: '/temperature',
    pathMatch: 'full'
  }
];


@NgModule({
  declarations: [
    AppComponent,
    SchedulesComponent,
    TemperaturesComponent,
    LoginComponent,
    AddscheduleComponent,
    EnergyComponent,
    EnergyListComponent,
    AlertComponent,
    PressureComponent,
    TemperaturehistoryComponent,
    StatuspageComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMyDatePickerModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, relativeLinkResolution: 'legacy' } // <-- debugging purposes only
 // <-- debugging purposes only
    )
  ],
  providers: [
        AuthenticationService,
        AuthGuard,
        ErrorInterceptor,
        SocketService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
