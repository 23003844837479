/*
	 {
    "dateOfReading": "2018-11-28",
    "dayKwh": 19.17,
    "minTemp": -15.31,
    "maxTemp": -12,
    "avgTemp": -14.16,
    "consumptionRunningTotal": 725.28
  }
*/
export class EnergyStatisticsMonth {
  public dateOfReading: string;
  public dayKwh: number;
  public minTemp: number;
  public maxTemp: number;
  public avgTemp: number;
  public consumptionRunningTotal: number;
  public avgConsumptionInWatts: number;

  // this callback runs after the object is deserialized. JSON can be altered here
  public static OnDeserialized(instance: EnergyStatisticsMonth): void {
    instance.avgConsumptionInWatts = Math.floor((instance.dayKwh * 1000) / 24);
  }
}
