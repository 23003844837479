import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import {GlobalVariable} from '../global';

import {PressureReadingModelSimple} from '../models/pressure-simple-model';
import {PressureReadHeadModel} from '../models/pressure-head-model';


@Injectable()
export class PressureService {
  constructor(private http: HttpClient) {}

  getLatest(sensorId: String): Observable<PressureReadingModelSimple> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '8';
    }

    return this.http.get<PressureReadingModelSimple>(GlobalVariable.BASE_API_URL + '/pressure/' + sensorId);
  }

  getLastDay(sensorId: String): Observable<PressureReadHeadModel> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '8';
    }

    return this.http.get<PressureReadHeadModel>(GlobalVariable.BASE_API_URL + '/pressure/' + sensorId + '/interval/HOURS/24');
  }
}
