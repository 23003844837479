<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-menu is-active">
    <div class="navbar-start">
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link" routerLink="/temperature" routerLinkActive="active">Temperatur</a>
        <div class="navbar-dropdown">
          <div class="navbar-item">
            <a routerLink="/temperature/6" routerLinkActive="active">Mässtensvägen - Utomhus</a>
          </div>
          <div class="navbar-item">
            <a routerLink="/temperature/1" routerLinkActive="active">Garage</a>
          </div>
          <div class="navbar-item">
            <a routerLink="/temperature/8" routerLinkActive="active">Garage-Förråd</a>
          </div>
        </div>
      </div>

      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link" routerLink="/energy" routerLinkActive="active">Energi</a>
        <div class="navbar-dropdown">
          <div class="navbar-item">
            <a routerLink="/energy" routerLinkActive="active">Nuvarande förbrukning</a>
          </div>
        </div>
      </div>

      <a class="navbar-item is-hoverable" routerLink="/pressure" routerLinkActive="active">Lufttryck</a>
      <a class="navbar-item is-hoverable" routerLink="/status" routerLinkActive="active">Status</a>

      <div class="navbar-item" *ngIf="authGuard.isLoggedIn()">
        <a routerLink="/schedule" routerLinkActive="active">Schemaläggning</a>
      </div>

    </div>
    <div class="navbar-end">
      <div class="navbar-item has-dropdown is-hoverable" *ngIf="authGuard.isLoggedIn()">
        <a class="navbar-link" routerLink="/user" routerLinkActive="active">{{authGuard.currentUser()}}</a>
        <div class="navbar-dropdown">
          <div class="navbar-item">
            <a routerLink="/logout" routerLinkActive="active">Logout</a>
          </div>
        </div>
      </div>
      
      <div class="navbar-item" *ngIf="!authGuard.isLoggedIn()">
        <a routerLink="/login" routerLinkActive="active">Login</a>
      </div>
    </div>
  </div>
</nav>
<alert></alert>
<router-outlet></router-outlet>