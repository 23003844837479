import { Component } from '@angular/core';
import { AuthGuard } from './auth/auth-guard.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Mässtensvägen';
  constructor(public authGuard: AuthGuard) { }
}
