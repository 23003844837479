import { Injectable } from '@angular/core';

const SERVER_URL = 'wss://temperatur.alone.se/ws';

@Injectable()
export class SocketService {
  private socket: WebSocket;

  constructor() {
    this.socket = new WebSocket(SERVER_URL);
    this.socket.onopen = (event) => { console.log('Energy WebSocket - Connected'); };
    this.socket.onclose = (event) => { console.log('Energy WebSocket - Closing'); };
  }

  public getWebsocket(): WebSocket {
    return this.socket;
  }
}
