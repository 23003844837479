<div class="container is-fluid">
  <h1 class="title">Schemaläggning</h1>
  <p class="field">
    <a class="button" [routerLink]="['/schedule/add']">
      <span class="icon">
        <i class="fa fa-plus"></i>
      </span>
      <span>Lägg till schema</span>
  </a>
  </p>
  <div *ngIf="schedules && schedules.length > 0">
    <table class="table is-hoverable">
      <thead>
        <tr>
          <th>Id</th>
          <th>Datum</th>
          <th>Switch</th>
          <th>Starttid</th>
          <th>Sluttid</th>
          <th>Temperatur</th>
          <th>Körtid (Minuter)</th>
          <th>Startad</th>
          <!--th></th-->
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let schedule of schedules">
          <td>{{schedule.id}}</td>
          <td>{{schedule.scheduleStartDateTime | date:'yyyy-MM-dd'}}</td>
          <td>{{schedule.switchDesc}}</td>
          <td>{{schedule.scheduleStartDateTime | date:'HH:mm'}}</td>
          <td>{{schedule.scheduleEndDateTime | date:'HH:mm'}}</td>
          <td>{{schedule.scheduleTemperature}}</td>
          <td>{{schedule.scheduleRunTime}}</td>
          <td>{{schedule.scheduleActive}}</td>
          <!--td><span class="fa fa-edit" (click)="this.editSchedule(schedule.id)"></span></td-->
          <td><span class="fa fa-times" (click)="this.deleteSchedule(schedule.id)"></span></td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="!schedules || schedules.length == 0">
    No schedules at this time
  </div>
</div>
