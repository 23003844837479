// Global variables

export const GlobalVariable = Object.freeze({
// Local network
// BASE_API_URL: 'http://192.168.135.31:7788/warmitup',
// BASE_LOGIN_URL: 'http://192.168.135.31:7788/',

// External URL
BASE_API_URL: 'https://temperatur.alone.se/api/warmitup',
BASE_LOGIN_URL: 'https://temperatur.alone.se/api/',

// BASE_API_URL: '/api/warmitup',
// BASE_LOGIN_URL: '/api/',

// Local host development
 //BASE_API_URL: 'http://localhost:8080/warmitup',
 //BASE_LOGIN_URL: 'http://localhost:8080/',
});
