export class SchedulePostDTO {
    switchId: Number;
    scheduledStartTime: Date;
    scheduledEndTime: Date;

    constructor(switchId: Number, scheduledStartTime: Date, scheduledEndTime: Date) {
        this.switchId = switchId;
        this.scheduledStartTime = scheduledStartTime;
        this.scheduledEndTime = scheduledEndTime;
    }
}
