import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnergyService } from '../services/energy-service';

import { Observable, Subscription, timer, fromEvent } from 'rxjs';
import 'rxjs/add/operator/switchMap';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { EnergyReadingModel } from '../models/energy-reading-model';
import { TemperatureService } from '../services/temperature.service';
import { TemperatureReadingModel } from '../models/tempread-reading-model';

import { SocketService } from '../services/socket-service';
import { WebSocketSubject } from 'rxjs/observable/dom/WebSocketSubject';
import { ChartType } from 'chart.js';


@Component({
  selector: 'app-energy',
  templateUrl: './energy.component.html',
  styleUrls: ['./energy.component.css'],
  providers: [EnergyService, TemperatureService]
})

export class EnergyComponent implements OnInit, OnDestroy {
  public lineChartLabels: any = new Object();
  public lineChartData: Array<any> = new Array<any>();

  public lastEnergyReadingObj: EnergyReadingModel;
  public energyReadingsArr: EnergyReadingModel[];
  public tempReadingsArr: TemperatureReadingModel[];

  private timer: Observable<number>;
  // Subscription object
  private sub: Subscription;
  private wsSub: Subscription;

  constructor(
    private energyService: EnergyService,
    private temperatureService: TemperatureService,
    private route: ActivatedRoute,
    private socketService: SocketService
  ) { }

  public lineChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
            maxTicksLimit: 12
          }
        }],
      yAxes: [
        {
          name: 'Energy',
          id: 'ENERGY',
          type: 'linear',
          position: 'left',
          ticks: {
            stepSize: 1000
          }
        },
        {
          name: 'Tempeature',
          id: 'B',
          type: 'linear',
          position: 'right',
          display: true,
          ticks: {
            suggestedMin: -30,
            suggestedMax: 30,
            stepSize: 10
          }
        }
      ]
    },
    elements: {
      point: {
        radius: 0.2
      }
    },
    legend: {
      display: false
    }
  };

  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartType : ChartType = 'line';

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }


  ngOnInit() {
    this.timer = timer(0, 5 * 60 * 1000);
    this.sub = this.timer.subscribe(t => this.updateData());

    // Create websocket
    const ws = this.socketService.getWebsocket();
    const observable$ = fromEvent(ws, 'message'); // Receives message events once you subscribe to this observable.

    this.wsSub = observable$.subscribe(val => {
      const messageEventVal = <MessageEvent> val;
      const data = JSON.parse(messageEventVal.data);
      console.log(data);
      /*
      "{\"timestamp\":\"2020-09-01T22:28:00+02:00\",\"power\":633,\"accumulatedConsumption\":20.303596,\"accumulatedCost\":13.875694,\"currency\":\"SEK\",\"minPower\":408,\"averagePower\":903.6,\"maxPower\":5646,\"voltagePhase1\":232,\"voltagePhase2\":232,\"voltagePhase3\":233,\"currentL1\":0.62,\"currentL2\":2.13,\"currentL3\":0.95,\"lastMeterConsumption\":4826.5}"
      */
      this.lastEnergyReadingObj.deltaConsumption = data.power - this.lastEnergyReadingObj.currentConsumptionInWatts;
      this.lastEnergyReadingObj.currentConsumptionInWatts = data.power;
      this.lastEnergyReadingObj.sensorReadingDate = data.timestamp;
      this.lastEnergyReadingObj.accumulatedConsumption = data.accumulatedConsumption;
      //this.lastEnergyReadingObj.accumulatedCost = data.accumulatedCost;
      this.lastEnergyReadingObj.accumulatedCost = 0; // this.currentFixedEnergyPrice.totalPrice * data.accumulatedConsumption;
      this.lastEnergyReadingObj.accumulatedConsumptionLastHour = data.accumulatedConsumptionLastHour;
      this.lastEnergyReadingObj.voltageL1 = data.voltagePhase1;
      this.lastEnergyReadingObj.voltageL2 = data.voltagePhase2;
      this.lastEnergyReadingObj.voltageL3 = data.voltagePhase3;
      this.lastEnergyReadingObj.currentL1 = data.currentL1;
      this.lastEnergyReadingObj.currentL2 = data.currentL2;
      this.lastEnergyReadingObj.currentL3 = data.currentL3;
    });

  }

  ngOnDestroy() {
    if (this.sub !== undefined && this.sub.unsubscribe !== undefined) {
      this.sub.unsubscribe();
    }

    if (this.wsSub !== undefined && this.wsSub.unsubscribe !== undefined) {
      this.wsSub.unsubscribe();
    }

    this.socketService.getWebsocket().close();
  }

  private updateData() {
    console.log('Running updateData()');

    this.energyService.getLatest('10').subscribe(
      data => {
        this.lastEnergyReadingObj = data;
      },
      error => console.log(error)
    );

    this.energyService.getLastDay('10').subscribe(
      data => {
        this.energyReadingsArr = data;
        this.fixlineChartData();
      },
      error => console.log(error)
    );

    this.temperatureService.getLastDay('6').subscribe(
      data => {
        this.tempReadingsArr = data.sensorReadingsLst;
        this.fixlineChartData();
      },
      error => console.log(error)
    );
  }

  private fixlineChartData() {
    const dataArrEnergy = new Array<number>();
    const dataArrTemp = new Array<number>();
    const labelArr = new Array<String>();
    const r = new Array<any>();

    // Delete old data, we get all the data at every poll
    this.lineChartData.splice(0, this.lineChartData.length);

    if (this.energyReadingsArr != null) {
      for (const element of this.energyReadingsArr) {
        dataArrEnergy.push(element.currentConsumptionInWatts);
        labelArr.push(moment(element.sensorReadingDate).format('DD/MM HH:mm'));
      }

      const returnObjectEnergy = new Object();
      returnObjectEnergy['data'] = dataArrEnergy;
      returnObjectEnergy['label'] = 'Energy';
      returnObjectEnergy['yAxisID'] = 'ENERGY';

      this.lineChartData.push(returnObjectEnergy);


      // Add Average value
      /*
      var avgEnergySum : number = dataArrEnergy.reduce(function(a, b) { return a + b; });
      var avgEnergyConsumption : number = avgEnergySum / dataArrEnergy.length;
      let returnObjectEnergyAvg = new Object();
      returnObjectEnergyAvg['data'] = this.fillArray(avgEnergyConsumption, dataArrEnergy.length);
      returnObjectEnergyAvg['label'] = "EnergyAvg";
      returnObjectEnergyAvg['yAxisID'] = "ENERGY";
      returnObjectEnergyAvg['fill'] = "false";
      this.lineChartData.push(returnObjectEnergyAvg);
      */
    }

    if (this.tempReadingsArr != null && labelArr != null) {
      for (const element of this.tempReadingsArr) {
        dataArrTemp.push(element.sensorValue);
        // console.log(element);
      }

      const returnObjectTemp = new Object();
      returnObjectTemp['data'] = dataArrTemp;
      returnObjectTemp['label'] = 'Temperature';
      returnObjectTemp['yAxisID'] = 'B';
      returnObjectTemp['fill'] = 'false';
      this.lineChartData.push(returnObjectTemp);
    }

    this.lineChartLabels = labelArr;
  }

  public fillArray(value, len) {
    const arr = [];

    for (let i = 0; i < len; i++) {
      arr.push(value);
    }

    return arr;
  }
}
