import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { GlobalVariable } from '../global';
import { AuthenticationService } from './index';

@Injectable()
export class ScheduleService {

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService) {}

    getAll():  Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', this.authenticationService.token);

      return this.http.get(GlobalVariable.BASE_API_URL + '/schedule/schedules/list', { headers: headers })
      .map(response => response);
    }

    postSchedule(schedule: string): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', this.authenticationService.token);
      headers = headers.append('Content-Type', 'application/json');

      console.log('Call for POST to schedule: ' + schedule);
      console.log(GlobalVariable.BASE_API_URL + '/schedule/schedules/');
      console.log(headers);

      return this.http.post(GlobalVariable.BASE_API_URL + '/schedule/schedules/', schedule, { headers: headers})
      .map(response => response);
    }

    deleteSchedule(scheduleId: string): Observable<any> {
      let headers = new HttpHeaders();
      headers = headers.append('Authorization', this.authenticationService.token);

      console.log('Call for delete of schedule: ' + scheduleId);
      console.log(GlobalVariable.BASE_API_URL + '/schedule/schedules/' + scheduleId);
      console.log(headers);

      return this.http.delete(GlobalVariable.BASE_API_URL + '/schedule/schedules/' + scheduleId, { headers: headers })
      .map(response => response);
    }
  }
