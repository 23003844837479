import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/map';
import { GlobalVariable } from '../global';
import { TemperatureReadingModelExtremes } from '../models/tempread-extremes-model';
import { TemperatureReadHeadModel } from '../models/tempread-head-model';
import { TemperatureReadingModelSimple } from '../models/tempread-simple-model';
import { TemperatureReadingModelHistory } from '../models/tempread-history-model';



@Injectable()
export class TemperatureService {
  constructor(private http: HttpClient) {}

  getLatest(sensorId: String): Observable<TemperatureReadingModelSimple> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '6';
    }

    return this.http.get<TemperatureReadingModelSimple>(GlobalVariable.BASE_API_URL + '/temperature/' + sensorId);
  }

  getLastDay(sensorId: String): Observable<TemperatureReadHeadModel> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '6';
    }

    return this.http.get<TemperatureReadHeadModel>(GlobalVariable.BASE_API_URL + '/temperature/' + sensorId + '/interval/HOURS/24');
  }


  getLastDayExtremes(sensorId: String): Observable<TemperatureReadingModelExtremes> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '6';
    }

    return this.http.get<TemperatureReadingModelExtremes>(GlobalVariable.BASE_API_URL + '/temperature/' + sensorId + '/extremes/DAYS/1');
  }


  getHistoryForSensor(sensorId: String): Observable<Array<TemperatureReadingModelHistory>> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '6';
    }

    return this.http.get<Array<TemperatureReadingModelHistory>>(GlobalVariable.BASE_API_URL + '/temperature/' + sensorId + '/history/');
  }
}
