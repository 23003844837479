import { Component, OnInit } from '@angular/core';
import { EnergyService } from '../services/energy-service';
import { EnergyStatisticsDay } from '../models/energy-statistics-day-model';
import { EnergyStatisticsMonth } from '../models/energy-statistics-month-model';

import * as moment from 'moment';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-energy-list',
  templateUrl: './energy-list.component.html',
  styleUrls: ['./energy-list.component.css'],
  providers: [EnergyService]
})

export class EnergyListComponent implements OnInit {
  energyReadingsDayArr: Array<EnergyStatisticsDay>;
  energyReadingsMonthArr: Array<EnergyStatisticsMonth>;

  public lineChartLabels: any = new Object();
  lineChartData: Array<any> = new Array<any>();

  constructor(
    private energyService: EnergyService) {}

    public lineChartOptions: any = {
      responsive: true,
      scales: {
        xAxes: [
          {
            ticks: {
              display: true,
              maxTicksLimit: 31
            }
          }],
          yAxes: [
            {
              name: 'Energy',
              id: 'ENERGY',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                stepSize: 10
              }
            },
            {
              name: 'Tempeature',
              id: 'TEMP',
              type: 'linear',
              position: 'right',
              display: true,
              ticks: {
                suggestedMin: -30,
                suggestedMax: 30,
                stepSize: 10
              }
            }
          ]
        },
        elements: {
          point: {
            radius: 3
          }
        },
        legend: {
          display: false
        }
      };
      
      public lineChartColors: Array<any> = [
        { // dark grey
          backgroundColor: 'rgba(77,83,96,0.2)',
          borderColor: 'rgba(77,83,96,1)',
          pointBackgroundColor: 'rgba(77,83,96,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(77,83,96,1)'
        },
        { // grey
          backgroundColor: 'rgba(148,159,177,0.2)',
          borderColor: 'rgba(148,159,177,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // Blue
          backgroundColor: 'rgba(50,162,236,0.2)',
          borderColor: 'rgba(50,162,236,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        { // Red
          backgroundColor: 'rgba(220,20,60,0.2)',
          borderColor: 'rgba(220,20,60,1)',
          pointBackgroundColor: 'rgba(148,159,177,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        }
      ];

      public lineChartType : ChartType = 'line';

      // events
      public chartClicked(e: any): void {
        console.log(e);
      }

      public chartHovered(e: any): void {
        console.log(e);
      }

    ngOnInit() {
      this.energyService.getDayStatisticalLst('7').subscribe(data => {
        this.energyReadingsDayArr = data;
      },
      error => console.log(error)
      );

      this.energyService.getDayStatisticalMonthLst('7').subscribe(data => {
        // Calculate avgConsumptionInWatts per element
        for (const inst of data) {
          EnergyStatisticsMonth.OnDeserialized(inst);
        }

        this.energyReadingsMonthArr = data;
        this.fixlineChartData();
      },
      error => console.log(error)
      );
    }

    private fixlineChartData() {
      const dataArrEnergy = new Array<number>();
      const dataArrTempAvg = new Array<number>();
      const dataArrTempMin = new Array<number>();
      const dataArrTempMax = new Array<number>();

      const labelArr = new Array<String>();

      // Delete old data, we get all the data at every poll
      this.lineChartData.splice(0, this.lineChartData.length);

      if (this.energyReadingsMonthArr != null) {
        for (const element of this.energyReadingsMonthArr) {
          dataArrEnergy.push(element.dayKwh);
          dataArrTempAvg.push(element.avgTemp);
          dataArrTempMin.push(element.minTemp);
          dataArrTempMax.push(element.maxTemp);

          labelArr.push(moment(element.dateOfReading).format('DD/MM-YY'));
        }

        const returnObjectEnergy = new Object();
        returnObjectEnergy['data'] = dataArrEnergy;
        returnObjectEnergy['label'] = 'Energy';
        returnObjectEnergy['fill'] = 'false';
        returnObjectEnergy['yAxisID'] = 'ENERGY';
        this.lineChartData.push(returnObjectEnergy);

        const returnObjectTempAvg = new Object();
        returnObjectTempAvg['data'] = dataArrTempAvg;
        returnObjectTempAvg['label'] = 'Avg temp';
        returnObjectTempAvg['fill'] = 'false';
        returnObjectTempAvg['yAxisID'] = 'TEMP';
        this.lineChartData.push(returnObjectTempAvg);

        const returnObjectTempMin = new Object();
        returnObjectTempMin['data'] = dataArrTempMin;
        returnObjectTempMin['label'] = 'Min temp';
        returnObjectTempMin['fill'] = 'false';
        returnObjectTempMin['yAxisID'] = 'TEMP';
        this.lineChartData.push(returnObjectTempMin);

        const returnObjectTempMax = new Object();
        returnObjectTempMax['data'] = dataArrTempMax;
        returnObjectTempMax['label'] = 'Max temp';
        returnObjectTempMax['fill'] = 'false';
        returnObjectTempMax['yAxisID'] = 'TEMP';
        this.lineChartData.push(returnObjectTempMax);
      }

      this.lineChartLabels = labelArr;
    }
}
