<section class="hero is-fullheight">
    <div class="hero-body">
        <div class="container has-text-centered">
            <div class="column is-4 is-offset-4">

                <h3 class="title has-text-grey">Login</h3>
                <p class="subtitle has-text-grey">Please login to proceed.</p>

                <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                        <div class="field ">
                            <div class="control">
                                <input 
                                    class="input is-large form-control" 
                                    type="text" 
                                    placeholder="Username"  
                                    name="username" 
                                    autofocus="" 
                                    [(ngModel)]="model.username" 
                                    #username="ngModel" 
                                    required
                                    autocorrect="off" 
                                    autocapitalize="none"/>
                            </div>
                        </div>    

                    </div>

                    <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                        <div class="field">
                            <div class="control">
                                <input 
                                    class="input is-large form-control" 
                                    type="password"  
                                    name="password" 
                                    placeholder="Password" 
                                    [(ngModel)]="model.password" 
                                    #password="ngModel" 
                                    required
                                    autocorrect="off" 
                                    autocapitalize="none"/>
                            </div>
                        </div>    
                    </div>

                    <div class="form-group">
                        <div class="form-group control">
                            <button type="submit" class="button is-primary is-block is-large is-fullwidth" [disabled]="loading" >Submit</button>
                        </div>
                        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </div>
                    <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                    <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
                    <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
                </form>
            </div>


            
        </div>
    </div>
    
</section>
<script async type="text/javascript" src="../js/bulma.js"></script>




