import { TemperatureService } from '../services/temperature.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';

import { TemperatureReadingModelSimple } from '../models/tempread-simple-model';
import { PressureReadingModelSimple } from '../models/pressure-simple-model';
import { EnergyReadingModel } from '../models/energy-reading-model';

import { Component, OnInit } from '@angular/core';
import { PressureService } from '../services/pressure-service';
import { EnergyService } from '../services/energy-service';

@Component({
  selector: 'app-statuspage',
  templateUrl: './statuspage.component.html',
  styleUrls: ['./statuspage.component.css'],
  providers: [TemperatureService, PressureService, EnergyService]
})
export class StatuspageComponent implements OnInit {
  temperatureModels: Array<TemperatureReadingModelSimple>;
  pressureObject: PressureReadingModelSimple;
  lastEnergyReadingObj: EnergyReadingModel;

  constructor(
    private temperatureService: TemperatureService,
    private pressureService: PressureService,
    private energyService: EnergyService,
    private route: ActivatedRoute
  ) { }


  ngOnInit(): void {
    // Holding array for temperature readings
    this.temperatureModels = new Array<TemperatureReadingModelSimple>();

    // Array of sensors ids to get 
    // const tempSensorIds: Array<string> = ['6', '1', '8'];
    const tempSensorIds: Array<string> = ['6'];

    // Get temperature value for sensor in array
    tempSensorIds.forEach(((value) => {
      this.temperatureService.getLatest(value).subscribe(
        (data: TemperatureReadingModelSimple) => {
          this.temperatureModels.push(data);
          console.log(data);
        },
        error => console.log(error)
      );
    }));

    // Get airpressure
    this.pressureService.getLatest('8').subscribe(
      (data: PressureReadingModelSimple) => {
        console.log(data);
        this.pressureObject = data;
      },
      error => console.log(error)
    );

    // Get latest energy reading
    this.energyService.getLatest('10').subscribe(
      data => {
        this.lastEnergyReadingObj = data;
      },
      error => console.log(error)
    );

  }

}
