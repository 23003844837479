import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {GlobalVariable} from '../global';

@Injectable()
export class AuthenticationService {
    public token: string;

    constructor(private http: HttpClient) {
        // set token if saved in local storage
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.token = currentUser && currentUser.token;
    }

    login(username: string, password: string): Observable<Boolean> {
        const httpOptions: object = {
            headers: new HttpHeaders({'Content-Type': 'application/json'}),
            observe: 'response'
        };

        // Convert username to lower case
        username = username.toLowerCase();

        return this.http.post<HttpResponse<String>>(GlobalVariable.BASE_LOGIN_URL + 'login', { username, password }, httpOptions)
        .map(
            response => {
                // Login successful if there's a jwt token in "Authorization" - Header
                // console.log(response.headers.get('Authorization'));
                // console.log(response);

                // Get Header "Authorization"

                const token = response.headers.get('Authorization');

                if (token) {
                    // set token property
                    this.token = token;

                    // store username and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify({ username: username, token: this.token }));

                    return true;
                }

                return false;
            });
    }

        logout(): void {
            // clear token remove user from local storage to log user out
            this.token = null;
            localStorage.removeItem('currentUser');
        }
    }
