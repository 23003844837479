import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScheduleService } from '../services/schedule.service';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.css'],
  providers: [ScheduleService]
})

export class SchedulesComponent implements OnInit {
  schedules: Array<any>;

  constructor(private scheduleService: ScheduleService, private router: Router) { }

  ngOnInit() {
    this.updateSchedules();
  }

  updateSchedules(): any {
    console.log('Update schedules!');

    this.scheduleService.getAll().subscribe(
      data => {
        this.schedules = data;
      },
      error => console.log(error)
      );
    }

    deleteSchedule(scheduleId: string) {
      this.scheduleService.deleteSchedule(scheduleId).subscribe(result => this.updateSchedules());
    }

    editSchedule(scheduleId: string) {
      this.router.navigate(['/schedule/' + scheduleId + '/edit']);
    }
  }
