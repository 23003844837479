<div class="container">
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
              Lufttryck - Mässtensvägen 21 
            </h1>
      </div>
    </div>
  </section>
</div>


<nav class="level">
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Lufttryck (hPa)</p>
      <p class="title">
        {{pressureObject?.sensorValue}}
      </p>
    </div>
  </div>

  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Datum</p>
      <p class="title">{{pressureObject?.sensorReadingDate | date:'dd/MM HH:mm'}}</p>
    </div>
  </div>
</nav>


<div class="columns is-mobile">
  <div class="column is-10 is-offset-1">
    <canvas *ngIf="lineChartData && lineChartData.data.length > 0" baseChart width="200" height="100" [data]="lineChartData.data" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
    </canvas>
  </div>
</div>