<script src="node_modules/chart.js/src/chart.js"></script>

<nav class="level">
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Nuvarande elförbrukning</p>
      <p class="title">{{lastEnergyReadingObj?.currentConsumptionInWatts}} W</p>
    </div>
  </div>

  <div class="level-item has-text-centered">
    <div>
      <p class="heading">&Delta;</p>

      <div *ngIf="lastEnergyReadingObj?.deltaConsumption; then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <p class="title">{{lastEnergyReadingObj?.deltaConsumption}} W</p>
      </ng-template>
      <ng-template #elseBlock>
        <p class="title">0 W</p>
      </ng-template>
    </div>
  </div>

  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Förbrukning (24h / 1h)</p>
      <p class="title">{{lastEnergyReadingObj?.accumulatedConsumption | number : '1.2-2'}} / {{lastEnergyReadingObj?.accumulatedConsumptionLastHour | number : '1.2-2'}} kWh</p>
    </div>
  </div>

  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Datum</p>
      <p class="title">{{lastEnergyReadingObj?.sensorReadingDate | date:'dd/MM HH:mm:ss'}}</p>
    </div>
  </div>
</nav>

<div class="level">
  <div class="level-item has-text-centered">
    <p>Senaste dygnet</p>
  </div>
</div>
<div class="columns is-mobile">
  <div class="column is-10 is-offset-1">
    <canvas *ngIf="lineChartData && lineChartData.length > 0" baseChart width="300" height="100"
      [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors"
      [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
    </canvas>
  </div>
</div>


<nav class="level">
  <div *ngIf="lastEnergyReadingObj?.voltageL1; then voltageCurrentBlock"></div>
  <ng-template #voltageCurrentBlock>
    <div class="level-item has-text-centered">
      <div>
        <p class="heading">L1</p>
        <p class="title">{{lastEnergyReadingObj?.voltageL1}} V</p>
        <p class="title">{{lastEnergyReadingObj?.currentL1}} A</p>
      </div>
    </div>

    <div class="level-item has-text-centered">
      <div>
        <p class="heading">L2</p>
        <p class="title">{{lastEnergyReadingObj?.voltageL2}} V</p>
        <p class="title">{{lastEnergyReadingObj?.currentL2}} A</p>
      </div>
    </div>

    <div class="level-item has-text-centered">
      <div>
        <p class="heading">L3</p>
        <p class="title">{{lastEnergyReadingObj?.voltageL3}} V</p>
        <p class="title">{{lastEnergyReadingObj?.currentL3}} A</p>
      </div>
    </div>
  </ng-template>
</nav>