<div class="container is-fluid">
  <h1 class="title">Statistik - Energi</h1>

  <!-- Energy - MONTH -->
  <div class="section">
    <h2 class="title">Förbrukning - Månad</h2>
    <div *ngIf="energyReadingsMonthArr && energyReadingsMonthArr.length > 0">
      <table class="table is-hoverable">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Förbrukning (Dag)</th>
            <th>Medeleffekt (Watt)</th>
            <th>Mintemperatur</th>
            <th>Maxtemperatur</th>
            <th>Medeltemperatur</th>
            <th>Akumulerad månadsförbrukning</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dayReading of energyReadingsMonthArr">
            <td>{{dayReading.dateOfReading}}</td>
            <td>{{dayReading.dayKwh}} kWh</td>
            <td>{{dayReading.avgConsumptionInWatts}}</td>
            <td>{{dayReading.minTemp}} °C</td>
            <td>{{dayReading.maxTemp}} °C</td>
            <td>{{dayReading.avgTemp}} °C</td>
            <td>{{dayReading.consumptionRunningTotal}} kWh</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="columns is-mobile section">
    <div class="column is-10 is-offset-1">
      <canvas *ngIf="lineChartData && lineChartData.length > 0"
        baseChart width="300" height="100" [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
      </canvas>
    </div>
  </div>

  <div class="section">
    <!-- Energy - Per hour -->
    <h2 class="title">Förbrukning - Dag</h2>
    <div *ngIf="energyReadingsDayArr && energyReadingsDayArr.length > 0">
      <table class="table is-hoverable">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Förbrukning (Timme)</th>
            <th>Medeltemperatur</th>
            <th>Akumulerad dagsförbrukning</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let dayReading of energyReadingsDayArr">
            <td>{{dayReading.hourOfReading | date:'yyyy-MM-dd HH:mm'}}</td>
            <td>{{dayReading.hourKwh}} kWh</td>
            <td>{{dayReading.avgTemp}} °C</td>
            <td>{{dayReading.consumptionRunningTotal}} kWh</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>