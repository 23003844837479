import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import {ChartsModule} from 'ng2-charts';
import * as moment from 'moment';

import {PressureService} from '../services/pressure-service';
import {PressureReadingModelSimple} from '../models/pressure-simple-model';
import {PressureReadHeadModel} from '../models/pressure-head-model';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-pressure',
  templateUrl: './pressure.component.html',
  styleUrls: ['./pressure.component.css'],
  providers: [PressureService]
})

export class PressureComponent implements OnInit {
  public lineChartLabels: any = new Object();
  lineChartData: any;

  pressureObject: PressureReadingModelSimple;
  pressureArr: PressureReadHeadModel;

  public lineChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          display: true,
          maxTicksLimit: 6
        }
      }],
      yAxes: [
        {
          name: 'Pressure',
          type: 'linear',
          position: 'right',
          display: true
        }
      ]
    },
    elements: {
      point: {
        radius: 4
      }
    },
    legend: {
      display: false
    }
  };

  public lineChartColors: Array<any> = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartType : ChartType = 'line';

  // events
  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  constructor(
    private pressureService: PressureService,
    private route: ActivatedRoute
    ) {}

    ngOnInit() {
      this.pressureService.getLatest('8').subscribe(
        (data: PressureReadingModelSimple) => {
          console.log(data);
          this.pressureObject = data;
        },
        error => console.log(error)
        );

        this.pressureService.getLastDay('8').subscribe(
          (data: PressureReadHeadModel) => {
            console.log(data);
            this.pressureArr = data;
            this.fixlineChartData();
          },
          error => console.log(error)
          );
        }

        private fixlineChartData() {
          const dataArr = new Array<Number>();
          const labelArr = new Array<String>();

          if (this.pressureArr != null) {
            const tempModel: Array<PressureReadingModelSimple> = this.pressureArr.sensorReadingsLst;


            for( const element of tempModel) {
              dataArr.push(element.sensorValue);
              labelArr.push(moment(element.sensorReadingDate).format('DD/MM HH:mm'));
            }

            const returnObject = new Object();
            returnObject['data'] = dataArr;
            returnObject['label'] = this.pressureArr['sensorDescription'];
            this.lineChartLabels = labelArr;
            this.lineChartData = returnObject;
          } else {
            const returnObject = new Object();
            returnObject['data'] = [];
            returnObject['label'] = '';
            this.lineChartLabels = [];
            this.lineChartData = returnObject;
          }
        }
      }
