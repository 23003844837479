<script src="node_modules/chart.js/src/chart.js"></script>
<div class="container">
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
              {{temperatureObject?.sensorDescription}}
            </h1>
      </div>
    </div>
  </section>
</div>

<nav class="level">
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Temperatur</p>
      <p class="title">
        {{temperatureObject?.sensorValue}} °C
      </p>
    </div>
  </div>

  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Datum</p>
      <p class="title">{{temperatureObject?.sensorReadingDate | date:'dd/MM HH:mm'}}</p>
    </div>
  </div>
</nav>

<nav class="level">
  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Min</p>
      <p class="heading">
        {{temperatureExtremes?.minTemp}} °C @ {{temperatureExtremes?.minTempTime | date:'dd/MM HH:mm'}}
      </p>
    </div>
  </div>

  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Max</p>
      <p class="heading">
        {{temperatureExtremes?.maxTemp}} °C @ {{temperatureExtremes?.maxTempTime | date:'dd/MM HH:mm'}}
      </p>
    </div>
  </div>

  <div class="level-item has-text-centered">
    <div>
      <p class="heading">Avg</p>
      <p class="heading">
        {{temperatureExtremes?.avgTemp}} °C
      </p>
    </div>
  </div>
</nav>

<div class="columns is-mobile">
  <div class="column is-10 is-offset-1">
    <canvas *ngIf="lineChartData && lineChartData.data.length > 0" baseChart width="400" height="200" [data]="lineChartData.data" [labels]="lineChartLabels" [options]="lineChartOptions" [colors]="lineChartColors" [chartType]="lineChartType" (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
    </canvas>
  </div>
</div>

<div class="column is-10 is-offset-1">
    <a [routerLink]="['/temperature/history/', sensorId]">Historik</a>
</div>