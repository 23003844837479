import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScheduleService } from '../services/schedule.service';
import { AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { SchedulePostDTO } from './schedulepostdto';

import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-addschedule',
  templateUrl: './addschedule.component.html',
  styleUrls: ['./addschedule.component.css'],
  providers: [ScheduleService]
})

export class AddscheduleComponent implements OnInit {
  constructor(private scheduleService: ScheduleService, private router: Router, private formBuilder: UntypedFormBuilder) { }
  addScheduleForm: UntypedFormGroup;

  model: IMyDateModel = null;

  public myDatePickerOptions: IAngularMyDpOptions = {
    // other options...
    inline: true,
    dateFormat: 'yyyy-mm-dd',
    markCurrentDay: true,
    dateRange: false,
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    disableUntil: {year: 0, month: 0, day: 0}
  };

  onSubmit() {
    let myStartDate = new Date(this.addScheduleForm.get('myDate').value.singleDate.jsDate);
    const myEndDate = new Date(this.addScheduleForm.get('myDate').value.singleDate.jsDate);
    const myStartTime = this.addScheduleForm.get('starttime').value;

    if (myStartTime != null && myStartTime !== '') {
      myStartDate.setHours(myStartTime.split(':')[0], myStartTime.split(':')[1]);
    } else {
      myStartDate = null;
    }

    const myEndTime = this.addScheduleForm.get('endtime').value;
    myEndDate.setHours(myEndTime.split(':')[0], myEndTime.split(':')[1]);

    const postObject = new SchedulePostDTO(this.addScheduleForm.get('switch').value, myStartDate, myEndDate);
    console.log(postObject);
    console.log(JSON.stringify(postObject));
    this.scheduleService.postSchedule(JSON.stringify(postObject))
    .subscribe(result => {
      console.log(result);
      this.router.navigate(['/schedule/']);
    });
  }

  onDateChanged(event: IMyDateModel): void {
    console.log('Date selected: ', event);
  }
  ngOnInit() {
    const d: Date = new Date();
    d.setDate(d.getDate() - 1);
    this.myDatePickerOptions.disableUntil = {year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()};

    this.addScheduleForm = this.formBuilder.group({
      switch: ['1', Validators.required],
      myDate: [this.model, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(10)])],
      endtime: ['07:00', Validators.required],
      starttime: [''],
    });

    this.model = {isRange: false, singleDate: {jsDate: new Date()}};
  }
}
