import {Component, OnInit, OnDestroy} from '@angular/core';
import {TemperatureService} from '../services/temperature.service';
import { Observable, Subscription, timer} from 'rxjs';
import 'rxjs/add/operator/switchMap';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as moment from 'moment';

import {TemperatureReadHeadModel} from '../models/tempread-head-model';
import {TemperatureReadingModel} from '../models/tempread-reading-model';
import {TemperatureReadingModelSimple} from '../models/tempread-simple-model';
import {TemperatureReadingModelExtremes} from '../models/tempread-extremes-model';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-temperatures',
  templateUrl: './temperatures.component.html',
  styleUrls: ['./temperatures.component.css'],
  providers: [TemperatureService]
})
export class TemperaturesComponent implements OnInit, OnDestroy {
  constructor(
    private temperatureService: TemperatureService,
    private route: ActivatedRoute
    ) {}

    public lineChartLabels: any = new Object();
    lineChartData: any;

    temperatureObject: TemperatureReadingModelSimple;
    temperatureArr: TemperatureReadHeadModel;
    temperatureExtremes: TemperatureReadingModelExtremes;

    sensorId: String;
    sensorIdSub: Subscription;

    private timer: Observable<number>;
    // Subscription object
    private sub: Subscription;

    public lineChartOptions: any = {
      responsive: true,
      scales: {
        xAxes: [{
          ticks: {
            display: true,
            maxTicksLimit: 6
          }
        }]
      },
      elements: {
        point: {
          radius: 0
        }
      },
      legend: {
        display: false
      }
    };

    public lineChartColors: Array<any> = [
      { // grey
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      },
      { // dark grey
        backgroundColor: 'rgba(77,83,96,0.2)',
        borderColor: 'rgba(77,83,96,1)',
        pointBackgroundColor: 'rgba(77,83,96,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,83,96,1)'
      },
      { // grey
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)'
      }
    ];

    public lineChartType : ChartType = 'line';

    ngOnInit() {
      this.sensorIdSub = this.route.paramMap
      .switchMap((params: ParamMap) =>
      this.sensorId = String(params.get('id'))).subscribe(t => this.updateData());

      this.timer = timer(0, 60 * 1000);
      this.sub = this.timer.subscribe(t => this.updateData());
    }

    ngOnDestroy() {
      if (this.sub !== undefined && this.sub.unsubscribe !== undefined) {
        this.sub.unsubscribe();
      }

      if (this.sensorIdSub !== undefined && this.sensorIdSub.unsubscribe !== undefined) {
        this.sensorIdSub.unsubscribe();
      }
    }

    private updateData() {
      this.temperatureArr = null;
      this.lineChartLabels = new Object();
      this.lineChartData = null;

      this.temperatureService.getLatest(this.sensorId).subscribe(
        (data: TemperatureReadingModelSimple) => {
          this.temperatureObject = data;
        },
        error => console.log(error)
        );

        this.temperatureService.getLastDay(this.sensorId).subscribe(
          (data: TemperatureReadHeadModel) => {
            this.temperatureArr = data;
            this.fixlineChartData();
          },
          error => console.log(error)
          );

          this.temperatureService.getLastDayExtremes(this.sensorId).subscribe(
            (data: TemperatureReadingModelExtremes) => {
              this.temperatureExtremes = data;
            },
            error => console.log(error)
            );
          }

          private fixlineChartData() {
            const dataArr = new Array<Number>();
            const labelArr = new Array<String>();

            if (this.temperatureArr != null) {
              const tempModel: Array<TemperatureReadingModel> = this.temperatureArr.sensorReadingsLst;

              for (const element of tempModel) {
                dataArr.push(element.sensorValue);
                // labelArr.push(moment(new Date(element.sensorReadingDate)).format('DD/MM HH:mm'));
                labelArr.push(moment(element.sensorReadingDate).format('DD/MM HH:mm'));
              }

              const returnObject = new Object();
              returnObject['data'] = dataArr;
              returnObject['label'] = this.temperatureArr['sensorDescription'];
              returnObject['fill'] = true;
              this.lineChartLabels = labelArr;
              this.lineChartData = returnObject;
            } else {
              const returnObject = new Object();
              returnObject['data'] = [];
              returnObject['label'] = '';
              this.lineChartLabels = [];
              this.lineChartData = returnObject;
            }

          }

          // events
          public chartClicked(e: any): void {
            console.log(e);
          }

          public chartHovered(e: any): void {
            console.log(e);
          }
        }
