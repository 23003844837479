<script src="node_modules/chart.js/src/chart.js"></script>
<div class="container">
    <section class="hero">
        <div class="hero-body">
            <div class="container">
                <h1 class="title">
                    {{historyHead?.sensorDescription}}
                </h1>
            </div>
        </div>
    </section>
</div>

<div class="columns is-mobile">
    <div class="column is-10 is-offset-1">
        <canvas *ngIf="lineChartData && lineChartData.length > 0" baseChart width="300" height="100"
            [datasets]="lineChartData" [labels]="lineChartLabels" [options]="lineChartOptions"
            [colors]="lineChartColors" [chartType]="lineChartType" (chartHover)="chartHovered($event)"
            (chartClick)="chartClicked($event)">
        </canvas>
    </div>
</div>