<div class="container is-fluid">
  <h1 class="title">Lägg till schema</h1>
  <form name="form" (ngSubmit)="onSubmit()" [formGroup]="addScheduleForm">
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Switch</label>
          <div class="control">
            <div class="select">
              <select formControlName="switch">
                <option value="1">Garage - Höger</option>
                <option value="51">Garage - Vänster</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Datum</label>
          <div class="control">
            <div>
              
  <input class="form-control" type="hidden" placeholder="Select a date" angular-mydatepicker name="myDate" 
  formControlName="myDate"[options]="myDatePickerOptions" #dp="angular-mydatepicker"/>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Start</label>
          <div class="control">
            <div class="select">
              <select formControlName="starttime">
                <!-- 00:00 -->
                <option value="00:00">00:00</option>
                <option value="00:15">00:15</option>
                <option value="00:30">00:30</option>
                <option value="00:45">00:45</option>
                <!-- 01:00 -->
                <option value="01:00">01:00</option>
                <option value="01:15">01:15</option>
                <option value="01:30">01:30</option>
                <option value="01:45">01:45</option>
                <!-- 02:00 -->
                <option value="02:00">02:00</option>
                <option value="02:15">02:15</option>
                <option value="02:30">02:30</option>
                <option value="02:45">02:45</option>
                <!-- 03:00 -->
                <option value="03:00">03:00</option>
                <option value="03:15">03:15</option>
                <option value="03:30">03:30</option>
                <option value="03:45">03:45</option>
                <!-- 04:00 -->
                <option value="04:00">04:00</option>
                <option value="04:15">04:15</option>
                <option value="04:30">04:30</option>
                <option value="04:45">04:45</option>
                <!-- 05:00 -->
                <option value="05:00">05:00</option>
                <option value="05:15">05:15</option>
                <option value="05:30">05:30</option>
                <option value="05:45">05:45</option>
                <!-- 06:00 -->
                <option value="06:00">06:00</option>
                <option value="06:15">06:15</option>
                <option value="06:30">06:30</option>
                <option value="06:45">06:45</option>
                <!-- 07:00 -->
                <option value="07:00">07:00</option>
                <option value="07:15">07:15</option>
                <option value="07:30">07:30</option>
                <option value="07:45">07:45</option>
                <!-- 08:00 -->
                <option value="08:00">08:00</option>
                <option value="08:15">08:15</option>
                <option value="08:30">08:30</option>
                <option value="08:45">08:45</option>
                <!-- 09:00 -->
                <option value="09:00">09:00</option>
                <option value="09:15">09:15</option>
                <option value="09:30">09:30</option>
                <option value="09:45">09:45</option>
                <!-- 10:00 -->
                <option value="10:00">10:00</option>
                <option value="10:15">10:15</option>
                <option value="10:30">10:30</option>
                <option value="10:45">10:45</option>
                <!-- 11:00 -->
                <option value="11:00">11:00</option>
                <option value="11:15">11:15</option>
                <option value="11:30">11:30</option>
                <option value="11:45">11:45</option>
                <!-- 12:00 -->
                <option value="12:00">12:00</option>
                <option value="12:15">12:15</option>
                <option value="12:30">12:30</option>
                <option value="12:45">12:45</option>
                <!-- 13:00 -->
                <option value="13:00">13:00</option>
                <option value="13:15">13:15</option>
                <option value="13:30">13:30</option>
                <option value="13:45">13:45</option>
                <!-- 14:00 -->
                <option value="14:00">14:00</option>
                <option value="14:15">14:15</option>
                <option value="14:30">14:30</option>
                <option value="14:45">14:45</option>
                <!-- 15:00 -->
                <option value="15:00">15:00</option>
                <option value="15:15">15:15</option>
                <option value="15:30">15:30</option>
                <option value="15:45">15:45</option>
                <!-- 16:00 -->
                <option value="16:00">16:00</option>
                <option value="16:15">16:15</option>
                <option value="16:30">16:30</option>
                <option value="16:45">16:45</option>
                <!-- 17:00 -->
                <option value="17:00">17:00</option>
                <option value="17:15">17:15</option>
                <option value="17:30">17:30</option>
                <option value="17:45">17:45</option>
                <!-- 18:00 -->
                <option value="18:00">18:00</option>
                <option value="18:15">18:15</option>
                <option value="18:30">18:30</option>
                <option value="18:45">18:45</option>
                <!-- 19:00 -->
                <option value="19:00">19:00</option>
                <option value="19:15">19:15</option>
                <option value="19:30">19:30</option>
                <option value="19:45">19:45</option>
                <!-- 20:00 -->
                <option value="20:00">20:00</option>
                <option value="20:15">20:15</option>
                <option value="20:30">20:30</option>
                <option value="20:45">20:45</option>
                <!-- 21:00 -->
                <option value="21:00">21:00</option>
                <option value="21:15">21:15</option>
                <option value="21:30">21:30</option>
                <option value="21:45">21:45</option>
                <!-- 22:00 -->
                <option value="22:00">22:00</option>
                <option value="22:15">22:15</option>
                <option value="22:30">22:30</option>
                <option value="22:45">22:45</option>
                <!-- 23:00 -->
                <option value="23:00">23:00</option>
                <option value="23:15">23:15</option>
                <option value="23:30">23:30</option>
                <option value="23:45">23:45</option>
              </select>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="columns">
      <div class="column">
        <div class="field">
          <label class="label">Slut</label>
          <div class="control">
            <div class="select">
              <select formControlName="endtime">
                <!-- 00:00 -->
                <option value="00:00">00:00</option>
                <option value="00:15">00:15</option>
                <option value="00:30">00:30</option>
                <option value="00:45">00:45</option>
                <!-- 01:00 -->
                <option value="01:00">01:00</option>
                <option value="01:15">01:15</option>
                <option value="01:30">01:30</option>
                <option value="01:45">01:45</option>
                <!-- 02:00 -->
                <option value="02:00">02:00</option>
                <option value="02:15">02:15</option>
                <option value="02:30">02:30</option>
                <option value="02:45">02:45</option>
                <!-- 03:00 -->
                <option value="03:00">03:00</option>
                <option value="03:15">03:15</option>
                <option value="03:30">03:30</option>
                <option value="03:45">03:45</option>
                <!-- 04:00 -->
                <option value="04:00">04:00</option>
                <option value="04:15">04:15</option>
                <option value="04:30">04:30</option>
                <option value="04:45">04:45</option>
                <!-- 05:00 -->
                <option value="05:00">05:00</option>
                <option value="05:15">05:15</option>
                <option value="05:30">05:30</option>
                <option value="05:45">05:45</option>
                <!-- 06:00 -->
                <option value="06:00">06:00</option>
                <option value="06:15">06:15</option>
                <option value="06:30">06:30</option>
                <option value="06:45">06:45</option>
                <!-- 07:00 -->
                <option value="07:00">07:00</option>
                <option value="07:15">07:15</option>
                <option value="07:30">07:30</option>
                <option value="07:45">07:45</option>
                <!-- 08:00 -->
                <option value="08:00">08:00</option>
                <option value="08:15">08:15</option>
                <option value="08:30">08:30</option>
                <option value="08:45">08:45</option>
                <!-- 09:00 -->
                <option value="09:00">09:00</option>
                <option value="09:15">09:15</option>
                <option value="09:30">09:30</option>
                <option value="09:45">09:45</option>
                <!-- 10:00 -->
                <option value="10:00">10:00</option>
                <option value="10:15">10:15</option>
                <option value="10:30">10:30</option>
                <option value="10:45">10:45</option>
                <!-- 11:00 -->
                <option value="11:00">11:00</option>
                <option value="11:15">11:15</option>
                <option value="11:30">11:30</option>
                <option value="11:45">11:45</option>
                <!-- 12:00 -->
                <option value="12:00">12:00</option>
                <option value="12:15">12:15</option>
                <option value="12:30">12:30</option>
                <option value="12:45">12:45</option>
                <!-- 13:00 -->
                <option value="13:00">13:00</option>
                <option value="13:15">13:15</option>
                <option value="13:30">13:30</option>
                <option value="13:45">13:45</option>
                <!-- 14:00 -->
                <option value="14:00">14:00</option>
                <option value="14:15">14:15</option>
                <option value="14:30">14:30</option>
                <option value="14:45">14:45</option>
                <!-- 15:00 -->
                <option value="15:00">15:00</option>
                <option value="15:15">15:15</option>
                <option value="15:30">15:30</option>
                <option value="15:45">15:45</option>
                <!-- 16:00 -->
                <option value="16:00">16:00</option>
                <option value="16:15">16:15</option>
                <option value="16:30">16:30</option>
                <option value="16:45">16:45</option>
                <!-- 17:00 -->
                <option value="17:00">17:00</option>
                <option value="17:15">17:15</option>
                <option value="17:30">17:30</option>
                <option value="17:45">17:45</option>
                <!-- 18:00 -->
                <option value="18:00">18:00</option>
                <option value="18:15">18:15</option>
                <option value="18:30">18:30</option>
                <option value="18:45">18:45</option>
                <!-- 19:00 -->
                <option value="19:00">19:00</option>
                <option value="19:15">19:15</option>
                <option value="19:30">19:30</option>
                <option value="19:45">19:45</option>
                <!-- 20:00 -->
                <option value="20:00">20:00</option>
                <option value="20:15">20:15</option>
                <option value="20:30">20:30</option>
                <option value="20:45">20:45</option>
                <!-- 21:00 -->
                <option value="21:00">21:00</option>
                <option value="21:15">21:15</option>
                <option value="21:30">21:30</option>
                <option value="21:45">21:45</option>
                <!-- 22:00 -->
                <option value="22:00">22:00</option>
                <option value="22:15">22:15</option>
                <option value="22:30">22:30</option>
                <option value="22:45">22:45</option>
                <!-- 23:00 -->
                <option value="23:00">23:00</option>
                <option value="23:15">23:15</option>
                <option value="23:30">23:30</option>
                <option value="23:45">23:45</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <div class="field is-grouped">
          <div class="control">
            <button class="button is-link" [disabled]="!addScheduleForm.valid">Submit</button>
          </div>
          <div class="control">
            <button class="button is-text">Cancel</button>
          </div>
        </div>
      </div>
    </div>
    <!--div *ngIf="error" class="alert alert-danger">{{error}}</div-->
  </form>
