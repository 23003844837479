import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs';
import {GlobalVariable} from '../global';
import {EnergyReadingModel} from '../models/energy-reading-model';
import { EnergyStatisticsDay } from '../models/energy-statistics-day-model';
import { EnergyStatisticsMonth } from '../models/energy-statistics-month-model';

@Injectable()
export class EnergyService {
  constructor(private http: HttpClient) {}

  getLatest(sensorId: String): Observable<EnergyReadingModel> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '10';
    }

    return this.http.get<EnergyReadingModel>(GlobalVariable.BASE_API_URL + '/energy/' + sensorId);
  }

  getLastDay(sensorId: String): Observable<EnergyReadingModel[]> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '1';
    }

    return this.http.get<EnergyReadingModel[]>(GlobalVariable.BASE_API_URL + '/energy/' + sensorId + '/interval/HOURS/24');
  }


  getDayStatisticalLst(sensorId: String): Observable<EnergyStatisticsDay[]> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '1';
    }

    return this.http.get<EnergyStatisticsDay[]>(GlobalVariable.BASE_API_URL + '/energy/' + sensorId + '/statistical/day');
  }

  getDayStatisticalMonthLst(sensorId: String): Observable<EnergyStatisticsMonth[]> {
    if (sensorId == null || sensorId === 'null') {
      sensorId = '1';
    }

    return this.http.get<EnergyStatisticsMonth[]>(GlobalVariable.BASE_API_URL + '/energy/' + sensorId + '/statistical/month');
  }
}
