import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../services/index';
import { AlertService } from '../auth/alertservice';

@Component({
    templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    error = '';

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private alertService: AlertService) { }

        ngOnInit() {
            console.log(this.router.url);

            if (this.router.url === '/logout') {
                this.logout();
                this.alertService.success('Logout');
                this.router.navigateByUrl('/');
            }
        }

        login() {
            this.loading = true;
            this.authenticationService.login(this.model.username, this.model.password)
            .subscribe(result => {
                console.log(result);

                if (result === true) {
                    this.alertService.error('Login successfull');
                    this.router.navigate(['/']);
                } else {
                    this.error = 'Username or password is incorrect';
                    this.loading = false;
                    this.alertService.error(this.error);
                }
            });
        }

        logout() {
            this.authenticationService.logout();
            this.alertService.success('Logout');
        }
    }
