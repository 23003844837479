<div class="container">
  <section class="hero">
    <div class="hero-body">
      <div class="container">
        <h1 class="title">
          Status - Senaste värden
        </h1>
      </div>
    </div>
  </section>



  <div class="tile is-ancestor">
    <div class="tile is-vertical is-12">
      <div class="tile">

        <div class="tile is-parent is-vertical" *ngFor="let tempModel of temperatureModels">
          <article class="tile is-child notification is-primary" >
            <p class="title">{{tempModel.sensorDescription}}</p>
            <p class="subtitle">{{tempModel.sensorValue}} °C</p>
            <div class="content">
              <!-- Content -->
              {{tempModel.sensorReadingDate | date:'yyyy-MM-dd HH:mm:ss'}}
            </div>
          </article>
        </div>

        <div class="tile is-parent is-vertical">
          <article class="tile is-child notification is-primary" >
            <p class="title">Energi</p>
            <p class="subtitle">{{lastEnergyReadingObj?.currentConsumptionInWatts}} W</p>
            <div class="content">
              <!-- Content -->
              {{lastEnergyReadingObj?.sensorReadingDate | date:'yyyy-MM-dd HH:mm:ss'}}
            </div>
          </article>
        </div>

        <div class="tile is-parent is-vertical">
          <article class="tile is-child notification is-primary" >
            <p class="title">Lufttryck</p>
            <p class="subtitle">{{pressureObject?.sensorValue}} hPa</p>
            <div class="content">
              <!-- Content -->
              {{pressureObject?.sensorReadingDate | date:'yyyy-MM-dd HH:mm:ss'}}
            </div>
          </article>
        </div>

      </div>
    </div>
  </div>

</div>